<template xmlns="http://www.w3.org/1999/html">
	<div>
		<el-row>
			<el-col>
				<el-form :inline='true'>
					<!--					<el-form-item label="关键字:">-->
					<!--						<el-input v-model="filters.keyWord" @input="(e) => (filters.keyWord = utils.validForbid(e))" placeholder="项目编号/项目名称" clearable></el-input>-->
					<!--					</el-form-item>-->
					<el-form-item label="报告出具开始时间：">
						<el-date-picker
								v-model="filters.startData"
								format="yyyy-MM-dd"
								value-format="yyyy-MM-dd"
						>
						</el-date-picker>
					</el-form-item>
					<el-form-item label="报告出具结束时间：">
						<el-date-picker
								v-model="filters.endData"
								format="yyyy-MM-dd"
								value-format="yyyy-MM-dd"
						>
						</el-date-picker>
					</el-form-item>
<!--					<el-form-item label="">-->
<!--						<el-select v-model="filters.userAi" placeholder="提交者" clearable>-->
<!--							<el-option-->
<!--									v-for="item in entyTypeArr"-->
<!--									:key="item.value"-->
<!--									:label="item.name"-->
<!--									:value="item.value"-->
<!--							>-->
<!--							</el-option>-->
<!--						</el-select>-->
<!--					</el-form-item>-->
					<el-form-item>
						<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<!-- 列表 -->
		<el-table
				:data="tableData"
				border
				id="exportTab"
				style="width: 100%"
				v-loading="listLoading"
				ref="multipleTable"
				@current-change='currentChange'
				:highlight-current-row='true'
		>
			<el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
			<el-table-column prop="projectName" label="项目名称" align="center" width="" show-overflow-tooltip></el-table-column>
			<el-table-column prop="itemRepeats" label="审查总数（去重）" align="center" width="90" show-overflow-tooltip>
				<template slot="header" slot-scope="scope">
					{{'审查总数'}}<br>{{'（去重）'}}
				</template>
			</el-table-column>
			<el-table-column prop="humanTotalNum"  label="人工审查总数" align="center" width="90" show-overflow-tooltip>
				<template slot="header" slot-scope="scope">
					{{'审查总数'}}<br>{{'（人工）'}}
				</template>
			</el-table-column>
			<el-table-column prop="humanInAiStandard" class-name="performance-column" label="范围内的数量（人工）" align="center" width="160" show-overflow-tooltip>
				<el-table-column prop="humanInSmallAiStandard" class-name="performance-column" label="53" align="center" width="60" show-overflow-tooltip></el-table-column>
				<el-table-column prop="humanInMiddleAiStandard" class-name="performance-column" label="70" align="center" width="60" show-overflow-tooltip></el-table-column>
				<el-table-column prop="humanInAiStandard" class-name="performance-column" label="247" align="center" width="60" show-overflow-tooltip></el-table-column>
			</el-table-column>
<!--			<el-table-column prop="humanOutAiStandard" label="247范围外的数量（人工）" align="center" width="160" show-overflow-tooltip></el-table-column>-->
			<el-table-column prop="aiTotalNum" label="AI提交总数" align="center" width="120" show-overflow-tooltip>
				<template slot="header" slot-scope="scope">
					{{'提交总数'}}<br>{{'（AI）'}}
				</template>
			</el-table-column>
			<el-table-column prop="aiReportTotalNum" :label="'数量（AI被采纳）'" align="center" width="120" show-overflow-tooltip>
				<template slot="header" slot-scope="scope">
					{{'数量'}}<br>{{'（AI被采纳）'}}
				</template>
			</el-table-column>
			<el-table-column prop="aiReportAiStandardTotalNum" label="范围内数量（AI被采纳）" align="center" width="150" show-overflow-tooltip>
				<el-table-column prop="aiReportSmallAiStandardTotalNum" class-name="performance-column" label="53" align="center" width="65" show-overflow-tooltip></el-table-column>
				<el-table-column prop="aiReportMiddleAiStandardTotalNum" class-name="performance-column" label="70" align="center" width="65" show-overflow-tooltip></el-table-column>
				<el-table-column prop="aiReportAiStandardTotalNum" class-name="performance-column" label="247" align="center" width="65" show-overflow-tooltip></el-table-column>
			</el-table-column>
			<el-table-column prop="aiUnReportTotalNum" label="AI未被采纳数量" align="center" width="125" show-overflow-tooltip>
				<template slot="header" slot-scope="scope">
					{{'数量'}}<br>{{'（AI未被采纳）'}}
				</template>
			</el-table-column>
			<el-table-column prop="aiUnReportAiStandardTotalNum" label="AI未被采纳范围内数量" align="center" width="160" show-overflow-tooltip>
<!--				<template slot="header" slot-scope="scope">-->
<!--					{{'247范围内数量'}}<br>{{'（AI未被采纳）'}}-->
<!--				</template>-->
				<el-table-column prop="aiUnReportSmallAiStandardTotalNum" class-name="performance-column" label="53" align="center" width="60" show-overflow-tooltip></el-table-column>
				<el-table-column prop="aiUnReportMiddleAiStandardTotalNum" class-name="performance-column" label="70" align="center" width="60" show-overflow-tooltip></el-table-column>
				<el-table-column prop="aiUnReportAiStandardTotalNum" class-name="performance-column" label="247" align="center" width="60" show-overflow-tooltip></el-table-column>
			</el-table-column>
			<el-table-column prop="aiUnReportAiStandardInHumanTotalNum" label="AI未被采纳且在人工范围内" align="center" width="140" show-overflow-tooltip>
				<template slot="header" slot-scope="scope">
					{{'且在人工范围内'}}<br>{{'（AI未被采纳）'}}
				</template>
				<el-table-column prop="aiUnReportSmallAiStandardInHumanTotalNum"  label="53" align="center" width="60" show-overflow-tooltip></el-table-column>
				<el-table-column prop="aiUnReportMiddleAiStandardInHumanTotalNum"  label="70" align="center" width="60" show-overflow-tooltip></el-table-column>
				<el-table-column prop="aiUnReportAiStandardInHumanTotalNum"  label="247" align="center" width="60" show-overflow-tooltip></el-table-column>
			</el-table-column>
		</el-table>
		<!--底部工具条-->
<!--		<el-col :span="24" class="pageBar">-->
<!--			<el-pagination-->
<!--					@size-change="handleSizeChange"-->
<!--					@current-change="handleCurrentChange"-->
<!--					:current-page="pages.pageIndex"-->
<!--					:page-sizes="pages.pageArr"-->
<!--					:page-size="pages.pageSize"-->
<!--					layout="total, sizes, prev, pager, next, jumper"-->
<!--					:total="pages.dataCount">-->
<!--			</el-pagination>-->
<!--		</el-col>-->
		<el-dialog
				title=""
				:visible.sync="dialogDetailVisible"
				v-model="dialogDetailVisible"
				:close-on-click-modal="false"
				:destroy-on-close="true"
				width='50%'
		>
		
		</el-dialog>
	
	</div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
	getStatisticsKYJCAiStandardData,
	getStatisticsKYJCOpininDetail,
	
} from '@/api/api'
import * as XLSX from "xlsx";
import FileSaver from 'file-saver'

export default {
	components:{Toolbar},
	data() {
		return {
			entyTypeArr:[
				{
					name:'AI',
					value:true
				},
				{
					name:'人工',
					value:false
				}
			],
			dialogDetailVisible:false,
			utils: '',
			buttonList: [],
			needData: [],
			filters: {
				startData:undefined,
				endData:undefined,
				timeData:[],
				userAi:undefined,
			},
			tableData: [],
			areaArr: [],
			listLoading: false,
			//关于分页的obj
			pages: {
				pageSize: 20,//默认每页条数
				pageArr: [20, 50, 100, 300],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
		}
	},
	methods: {
		//导出
		async exportListData () {
			let wb = XLSX.utils.book_new();
			// 导出主表格
			let mainTable = document.querySelector('#exportTab');
			let xlsxParam = { raw: true };
			let mainSheet = XLSX.utils.table_to_sheet(mainTable, xlsxParam);
			XLSX.utils.book_append_sheet(wb, mainSheet, '审查情况分析');
			// 从多个子表格中导出数据
			// for (let item of this.tableData) {
			// 	this.tableDataList.projectList = [];
			// 	// 显示当前子表格的对话框
			// 	this.getProject(item)
			// 	// 等待对话框完全渲染（您可能需要实现这一部分）
			// 	await this.$nextTick();
			// 	let oneTable = document.querySelector('#personal');
			// 	let oneSheet = XLSX.utils.table_to_sheet(oneTable, xlsxParam);
			// 	// 在Excel工作表中使用唯一名称来标识每个子表格
			// 	XLSX.utils.book_append_sheet(wb, oneSheet, `子表-${item.userName}`);
			// 	// 关闭当前子表格的对话框
			// 	this.dialog.project.show = false;
			// }
			// 将工作簿导出为Excel文件
			let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
			try {
				FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '审查情况分析（开业检查）.xlsx');
			} catch (e) {
				if (typeof console !== 'undefined') {
					console.log(e, wbout);
				}
			}
			return wbout;
		},
		rowClass () {
			return 'padding:8px!important;'
		},
		callFunction(item) {
			this[item.func].apply(this, item);
		},
		// 列表查询接口
		getDataList(){
			console.log(this.filters.timeData,'timeData')
			let params = {
				// pageIndex: this.pages.pageIndex,
				// pageSize: this.pages.pageSize,
				startData:this.filters.startData,
				endData:this.filters.endData,
				// userIsAi:this.filters.userAi,
			}
			this.listLoading = true
			getStatisticsKYJCAiStandardData(params).then(res => {
				const result = res.data;
				if(result.success) {
					this.tableData = result.response
					// this.pages.dataCount = result.response.dataCount
				}
				this.listLoading = false
			})
		},
		
		// 查询
		getListData() {
			this.pages.pageIndex = 1
			this.getDataList();
		},
		
		// 点击table某一行
		currentChange(val) {
			this.needData = val
			console.log(val,'val')
			
		},
		// 分页
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getListData();
		},
		handleSizeChange(val){
			this.pages.pageSize = val;
			this.getListData();
		},
		// 时间格式化
		formatCreateTime: function(row, column) {
			return !row || row == ""
					? ""
					: util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
		},
		// 表格数据分页条数累计
		indexMethod(index) {
			var currentPage = this.pages.pageIndex
			var pageSize = this.pages.pageSize
			return (index + 1) + (currentPage - 1) * pageSize
		},
	},
	watch: {
	
	},
	created(){
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要+1
		const day = String(currentDate.getDate()).padStart(2, '0');
		
		const formattedDate = `${year}-${month}-${day}`;
		this.filters.startData = formattedDate
		this.filters.endData = formattedDate
		console.log(formattedDate,'formattedDate')
		this.utils = util
		let routers = window.localStorage.router
				? JSON.parse(window.localStorage.router)
				: [];
		this.buttonList = getButtonList(this.$route.path, routers);
		this.getListData();
	}
}
</script>

<style lang="stylus" scoped>
.projectName {
	text-align: center;
}
.projectSelect {
	margin-bottom: 15px;
}
.projectTitle {
	margin-bottom: 15px
}
.replyBtn {
	margin: 10px 0;
	color: #409eff;
	cursor: pointer;
	width: 30px;
}
.replyMessage {
	background: #eee;
	line-height: 30px;
	padding: 5px;
	border-radius: 2px;
	margin: 5px 15px;
}
.titleTimer {
	margin-left: 10px;
}
.green_t {
	color: green;
}
.red_t {
	color: red;
}
</style>
